<template>
  <div v-if="permission.skillWiseCandidate">
    <b-card title="Candidate List" no-body>
      <b-card-body>
        <b-row class="justify-content-between">
          <b-col cols="12" md="1"
                 class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1">Show</label>
            <Select
                v-model="search.paginate"
                :clearable="false"
            >
            <Option :value="10"> 10</Option>
            <Option :value="30"> 30</Option>
            <Option :value="50"> 50</Option>
            <Option :value="80"> 80</Option>
            <Option :value="100"> 100</Option>
            </Select>
          </b-col>
          <b-col cols="12" md="3">
            <DatePicker
                v-model="from_date"
                type="date"
                placeholder="Select From Date"
                style="width: 100%"
            >
            </DatePicker>
          </b-col>
          <b-col cols="12" md="3">
            <DatePicker
                v-model="to_date"
                type="date"
                placeholder="Select From Date"
                style="width: 100%"
            >
            </DatePicker>
          </b-col>
          <b-col cols="12" md="2">
            <Select
                v-model="search.type"
                @on-change="SearchData"
                :clearable="true"
                placeholder="Select Type"
            >
            <Option :value="1"> Soft Skills</Option>
            <Option :value="2"> Hard Skills</Option>
            </Select>
          </b-col>
          <b-col cols="12" md="3">
            <Input
                v-model="search.search_data"
                class="d-inline-block mr-1"
                placeholder="Search name..."
                type="text"
            />
          </b-col>
        </b-row>
      </b-card-body>
      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr class="text-center">
            <th>Sl</th>
            <th>name</th>
            <th>Type</th>
            <th>Total Candidate</th>
            <th>Approved Candidate</th>
            <th>Pending Candidate</th>
            <th>Rejected Candidate</th>
            <th>InReview Candidate</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-if="skills.data.length"
              v-for="(skill, index) in skills.data"
              :key="index"
              class="text-center"
          >
            <td class="align-middle text-center">
              {{ skills.from + index }}
            </td>
            <td class="align-middle text-center">
              <p>
                <b>{{ skill.name ? skill.name : "No data yet" }}</b>
              </p>
            </td>

            <td>
                <span
                    style="margin-bottom: 2px"
                    :class="
                          skill.type === 1
                            ? 'badge badge-success'
                            : skill.type === 2
                            ? 'badge badge-primary'
                            : ''
                        "
                >
                        {{
                    skill.type == "1"
                        ? "Soft Skill"
                        : skill.type == "2"
                            ? "Hard Skill"
                            : ""
                        }}
                      </span>
            </td>

            <td>
              {{ skill.total_candidate ? skill.total_candidate : "0" }}
            </td>

            <td>
              {{ skill.approved_candidate ? skill.approved_candidate : "0" }}
            </td>

            <td>
              {{ skill.pending_candidate ? skill.pending_candidate : "0" }}
            </td>

            <td>
              {{ skill.rejected_candidate ? skill.rejected_candidate : "0" }}
            </td>

            <td>
              {{ skill.inReview_candidate ? skill.inReview_candidate : "0" }}
            </td>
          </tr>

          <tr v-if="!skills.data.length">
            <td class="text-center" colspan="7">
              <h4>No data found!</h4>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <b-card-body>
        <div
            class="d-flex justify-content-between align-items-center"
            v-if="skills"
        >
              <span class="text-muted"
              >Showing {{ skills.from }} to {{ skills.to }} of
                {{ skills.total }} entries</span
              >
          <pagination
              :data="skills"
              @pagination-change-page="getResults"
              align="right"
              :show-disabled="true"
              :limit="10"
          ></pagination>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardBody,
  BFormFile,
} from "bootstrap-vue";
import _ from "lodash";

export default {
  data() {
    return {
      skills: {},
      search: {
        search_data: "",
        type: "",
        from_date: "",
        to_date: "",
        paginate: 10,
        page: 1,
      },
      from_date: "",
      to_date: "",
    };
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BCardBody,
    BFormFile,
  },

  mounted() {
    this.getResults();
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions.report;
    },
  },
  methods: {
    formatDate(date) {
      if (date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
      return null;
    },

    getResults(page) {
      if (this.from_date) {
        this.search.from_date = this.formatDate(this.from_date);
      }
      if (this.to_date) {
        this.search.to_date = this.formatDate(this.to_date);
      }
      this.search.page = page == null ? this.search.page : page;
      const queryParams = this.buildQueryParamString(this.search);
      axios
          .post(`/app/report/skill-wise-candidate${queryParams}`)
          .then((res) => {
            this.skills = res.data.skills;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  watch: {
    "search.search_data": function () {
      this.SearchData();
    },
    "search.type": function () {
      this.SearchData();
    },
    "from_date": function () {
      this.SearchData();
    },
    "to_date": function () {
      this.SearchData();
    },
    "search.paginate": function () {
      this.paginateChange();
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
}

.padding {
  padding: 0.72rem 11px !important;
}
</style>
